import React from 'react';
import { Container, Typography, Grid, Paper, Box } from '@mui/material';
import { styled } from '@mui/system';
import { keyframes } from '@emotion/react';

const fadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

const slideIn = keyframes`
  from {
    transform: translateY(50px);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
`;

const AnimatedContainer = styled(Container)`
  animation: ${fadeIn} 1.5s ease-in-out;
`;

const AnimatedPaper = styled(Paper)`
  animation: ${slideIn} 1.5s ease-in-out;
  padding: 24px;
  margin-top: 24px;
`;

const About = () => {
  return (
    <AnimatedContainer>
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <Box mt={8}>
            <Typography
              variant="h4"
              align="center"
              gutterBottom
              sx={{
                fontFamily: '"Times New Roman", Times, serif',
              }}
            >
              Quem Somos
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={12}>
          <AnimatedPaper elevation={1}>
            <Typography
              variant="body1"
              paragraph
              sx={{
                fontFamily: '"Times New Roman", Times, serif',
              }}
            >
              A <strong>JB Esquadrias Vidros Temperados</strong> é uma empresa de referência no mercado, dedicada à fabricação e instalação de esquadrias e vidros temperados de alta qualidade. Com anos de experiência, nosso compromisso é entregar soluções inovadoras, seguras e esteticamente sofisticadas para cada projeto.
            </Typography>
            <Typography
              variant="body1"
              paragraph
              sx={{
                fontFamily: '"Times New Roman", Times, serif',
              }}
            >
              Nossos profissionais são altamente qualificados e focados em garantir a satisfação total de nossos clientes. Trabalhamos com os melhores materiais e tecnologias disponíveis, sempre visando o melhor acabamento e durabilidade em cada serviço prestado.
            </Typography>
            <Typography
              variant="body1"
              paragraph
              sx={{
                fontFamily: '"Times New Roman", Times, serif',
              }}
            >
              Na <strong>JB Esquadrias Vidros Temperados</strong>, valorizamos a integridade, o respeito e a excelência em todas as etapas do processo, desde o primeiro contato até a entrega final do projeto. Nossa missão é transformar espaços, criando ambientes que combinam segurança, funcionalidade e beleza.
            </Typography>
            <Typography
              variant="body1"
              paragraph
              sx={{
                fontFamily: '"Times New Roman", Times, serif',
              }}
            >
              <strong>Vantagens de um Planejamento Bem Feito:</strong> Um planejamento detalhado garante que cada aspecto do projeto seja considerado, desde a escolha dos materiais até a execução final. Isso resulta em maior eficiência, redução de custos imprevistos e uma entrega mais ágil e dentro dos padrões esperados.
            </Typography>
            <Typography
              variant="body1"
              paragraph
              sx={{
                fontFamily: '"Times New Roman", Times, serif',
              }}
            >
              <strong>Benefícios dos Vidros Temperados:</strong> Os vidros temperados são mais seguros e resistentes em comparação com vidros comuns, suportando impactos e variações de temperatura. Eles proporcionam uma estética elegante e moderna, além de contribuir para a eficiência energética do edifício, ao manter a temperatura interna estável.
            </Typography>
            <Typography
              variant="body1"
              paragraph
              sx={{
                fontFamily: '"Times New Roman", Times, serif',
              }}
            >
              <strong>Benefícios das Esquadrias de Alta Qualidade:</strong> As esquadrias bem projetadas e fabricadas oferecem melhor vedação contra intempéries, maior durabilidade e menor necessidade de manutenção. Além disso, elas contribuem para o isolamento acústico e térmico, melhorando o conforto e a eficiência energética do ambiente.
            </Typography>
            <Typography
              variant="body1"
              paragraph
              sx={{
                fontFamily: '"Times New Roman", Times, serif',
              }}
            >
              Escolher a <strong>JB Esquadrias Vidros Temperados</strong> é optar por uma empresa que coloca o cliente em primeiro lugar, oferecendo produtos e serviços que superam as expectativas e se destacam no mercado.
            </Typography>
          </AnimatedPaper>
        </Grid>
      </Grid>
    </AnimatedContainer>
  );
}

export default About;
