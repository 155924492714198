import { addDoc, collection, getDocs, query, where } from 'firebase/firestore';
import { db } from './../../services/firebase';

export const fetchPortifolio = async (type) => {
    try {
        const q = query(collection(db, 'portifolio'), where('type', '==', type));

        const querySnapshot = await getDocs(q);

        const items = querySnapshot.docs.map(doc => {
            const data = doc.data();
            return {
                id: doc.id,
                ...data
            };
        });

        return items;
    } catch (error) {
        console.error("Error fetching portifolio: ", error);
        throw error;
    }
};

export const addPortfolio = async (portfolio) => {
    try {
      await addDoc(collection(db, 'portifolio'), portfolio);
    } catch (error) {
      console.error("Error adding portfolio: ", error);
      throw error;
    }
  };