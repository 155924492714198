import React from 'react';
import { BrowserRouter as Router, Route, Routes, useLocation, Navigate } from 'react-router-dom';
import Header from './components/Header';
import About from './pages/About';
import Portfolio from './pages/Portfolio';
import Contact from './pages/Contact';
import Footer from './components/FooterContainer';
import Register from './pages/Register';

const App = () => {
  const location = useLocation();
  let activePage = '';

  switch (location.pathname) {
    case '/':
      activePage = 'portfolio';
      break;
    case '/about':
      activePage = 'about';
      break;
    case '/portfolio':
      activePage = 'portfolio';
      break;
    case '/contact':
      activePage = 'contact';
      break;
    default:
      activePage = 'portfolio';
  }

  return (
    <div style={{ display: 'flex', flexDirection: 'column', minHeight: '100vh' }}>
      <Header activePage={activePage} />
      <div style={{ flex: 1, paddingBottom: '100px' }}>
        <Routes>
          <Route path="/" element={<Portfolio />} />
          <Route path="/about" element={<About />} />
          <Route path="/portfolio" element={<Portfolio />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/62228776-4b72-4c56-b133-10bb2e4b9827" element={<Register />} />
          <Route path="*" element={<Navigate to="/portfolio" replace />} />
        </Routes>
      </div>
      <Footer />
    </div>
  );
}

const AppWrapper = () => (
  <Router>
    <App />
  </Router>
);

export default AppWrapper;
