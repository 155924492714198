import React from 'react';
import { Box, Typography, IconButton } from '@mui/material';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import { styled } from '@mui/system';

const FooterContainer = styled(Box)`
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  background-color: #f1f1f1;
  padding: 10px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  box-shadow: 0 -1px 5px rgba(0,0,0,0.1);
  box-sizing: border-box; /* Adicione box-sizing para garantir que o padding não cause overflow */
`;

const Footer = () => {
    const currentYear = new Date().getFullYear();

    return (
        <FooterContainer>
            <Typography variant="caption" color="textSecondary" align="center">
                © {currentYear} JB Esquadrias e Vidros Temperados - Todos os direitos reservados.
            </Typography>
            <Box display="flex" alignItems="center" mt={1}>
                <Typography variant="caption" color="textSecondary" mr={1}>
                    Whatsapp: +5516991839350
                </Typography>
                <IconButton
                    color="primary"
                    component="a"
                    href="https://wa.me/5516991839350"
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    <WhatsAppIcon />
                </IconButton>
            </Box>
        </FooterContainer>
    );
}

export default Footer;
