import { addDoc, collection, doc, getDoc, getDocs, setDoc } from 'firebase/firestore';
import { db } from './../../services/firebase';

export const fetchItems = async () => {
    try {
        const querySnapshot = await getDocs(collection(db, 'items'));
        const items = querySnapshot.docs.map(doc => {
            const data = doc.data();
            return {
                id: doc.id,
                name: data.name || 'No name',
                description: data.description || 'No description',
                type: data.type || 'No type'
            };
        });

        return items;
    } catch (error) {
        console.error("Error fetching items: ", error);
        throw error;
    }
};

export const saveNewItem = async (itemData) => {
    try {
        const docRef = await addDoc(collection(db, 'items'), {
            name: itemData.name || 'No name',
            description: itemData.description || 'No description',
            type: itemData.type || 'No type'
        });
        return docRef.id;
    } catch (error) {
        console.error("Error saving new item: ", error);
        throw error;
    }
};

export const updateItem = async (id, newItemData) => {
    try {
        const itemRef = doc(db, 'items', id);

        const itemDoc = await getDoc(itemRef);

        if (itemDoc.exists()) {
            await setDoc(itemRef, newItemData, { merge: true });
        } else {
            await setDoc(itemRef, newItemData);
        }
    } catch (error) {
        console.error("Error updating or adding item: ", error);
        throw error;
    }
};