import React, { useState } from 'react';
import { TextField, Button, Box, Typography } from '@mui/material';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import { keyframes } from '@mui/system';
import { styled } from '@mui/system';
import Container from '@mui/material/Container';

const fadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;


const AnimatedContainer = styled(Container)`
  animation: ${fadeIn} 1.5s ease-in-out;
`;

const Contact = () => {
    const [title, setTitle] = useState('');
    const [message, setMessage] = useState('');

    const handleSubmit = () => {
        const phone = '+5516991839350';
        const text = `${title}\n${message}`;
        const encodedText = encodeURIComponent(text);
        const url = `https://api.whatsapp.com/send?phone=${phone}&text=${encodedText}`;
        window.open(url, '_blank');
    };

    const isButtonDisabled = !title || !message;

    return (
        <AnimatedContainer>
            <Box sx={{ p: 3, maxWidth: 600, mx: 'auto', mt: 10 }}>
                <Typography
                    variant="h4"
                    gutterBottom
                    sx={{
                        fontFamily: '"Times New Roman", Times, serif',
                    }}
                >
                    Entre em contato
                </Typography>
                <TextField
                    label="Título do serviço (Ex.: Orçamento para instalação de box de banheiro)"
                    variant="outlined"
                    fullWidth
                    margin="normal"
                    value={title}
                    onChange={(e) => setTitle(e.target.value)}
                    sx={{
                        '& .MuiInputBase-input': {
                            fontFamily: '"Times New Roman", Times, serif',
                        },
                        '& .MuiInputLabel-root': {
                            fontFamily: '"Times New Roman", Times, serif',
                        }
                    }}
                />
                <TextField
                    label="Descrição detalhada do serviço (Ex.: Descreva os serviços que precisa, medidas, materiais, etc.)"
                    variant="outlined"
                    fullWidth
                    margin="normal"
                    multiline
                    rows={4}
                    value={message}
                    onChange={(e) => setMessage(e.target.value)}
                    sx={{
                        '& .MuiInputBase-input': {
                            fontFamily: '"Times New Roman", Times, serif',
                        },
                        '& .MuiInputLabel-root': {
                            fontFamily: '"Times New Roman", Times, serif',
                        }
                    }}
                />
                <Button
                    variant="contained"
                    color={isButtonDisabled ? 'info' : 'success'}
                    endIcon={<WhatsAppIcon />}
                    fullWidth
                    onClick={handleSubmit}
                    disabled={isButtonDisabled}
                    sx={{
                        '& .MuiInputBase-input': {
                            fontFamily: '"Times New Roman", Times, serif',
                        },
                        '& .MuiInputLabel-root': {
                            fontFamily: '"Times New Roman", Times, serif',
                        }
                    }}
                >
                    Solicitar Orçamento
                </Button>
            </Box>
        </AnimatedContainer>
    );
};

export default Contact;
