import React from 'react';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';

const PortfolioHeader = ({ items, selectedItem, onItemClick }) => {
    return (
        <AppBar position="static" sx={{ backgroundColor: '#ffff', marginBottom: '40px', boxShadow: 'none' }}>
            <Toolbar sx={{ justifyContent: 'center' }}>
                <Box
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        flexWrap: 'wrap',
                        maxWidth: '1200px',
                        width: '100%',
                        justifyContent: 'center'
                    }}
                >
                    {items.map((item, index) => (
                        <React.Fragment key={item.name}>
                            <Button
                                onClick={() => onItemClick(item)}
                                sx={{
                                    color: item === selectedItem ? '#030A50' : '#000000',
                                    fontWeight: item === selectedItem ? 'bold' : 'normal',
                                    fontSize: item === selectedItem ? '1rem' : '0.875rem',
                                    marginRight: index < items.length - 1 ? '15px' : '0',
                                    whiteSpace: 'nowrap',
                                    fontFamily: '"Times New Roman", Times, serif'
                                }}
                            >
                                {item.name}
                            </Button>
                            {index < items.length - 1 && <span style={{ margin: '0 6px', color: '#000000' }}>|</span>}
                        </React.Fragment>
                    ))}
                </Box>
            </Toolbar>
        </AppBar>
    );
}

export default PortfolioHeader;