import React, { useState, useEffect } from 'react';
import { Box, Typography, TextField, Button, MenuItem, Container, Tabs, Tab, Snackbar, Dialog, DialogTitle, DialogContent, DialogActions } from '@mui/material';
import { fetchItems, saveNewItem } from '../../dataprovider/items/itemsRepository';
import { addPortfolio } from '../../dataprovider/portifolio/portifolioRepository';

const Register = () => {
    const [loading, setLoading] = useState(false);
    const [newItemName, setNewItemName] = useState('');
    const [newItemDescription, setNewItemDescription] = useState('');
    const [portfolioType, setPortfolioType] = useState('');
    const [portfolioDescription, setPortfolioDescription] = useState('');
    const [portfolioImage, setPortfolioImage] = useState('');
    const [availableTypes, setAvailableTypes] = useState([]);
    const [tabIndex, setTabIndex] = useState(0);
    const [isButtonDisabled, setIsButtonDisabled] = useState(false);
    const [snackBarEnable, setSnackBarEnable] = useState(false);
    const [snackBarMessage, setSnackBarMessage] = useState('');
    const [showImage, setShowImage] = useState(false);

    const [buttonPortifolioEnable, setButtonPortifolioEnable] = useState(false);
    const [buttonAbaEnable, setButtonAbaEnable] = useState(false);

    useEffect(() => {
        const fetchData = async () => {
            if (newItemName !== '' && newItemDescription !== '') {
                setButtonAbaEnable(true)
            } else {
                setButtonAbaEnable(false)
            }
        }

        fetchData();
    }, [newItemName, newItemDescription])

    useEffect(() => {
        const fetchData = async () => {
            if (portfolioDescription !== '' && portfolioImage !== '' && portfolioType !== '') {
                setButtonPortifolioEnable(true)
            } else {
                setButtonPortifolioEnable(false);
            }
        }

        fetchData();
    }, [portfolioType, portfolioDescription, portfolioImage])

    useEffect(() => {
        const fetchAvailableTypes = async () => {
            setLoading(true);
            try {
                const fetchedItems = await fetchItems();
                const types = fetchedItems.map(item => item.type);
                setAvailableTypes([...new Set(types)]);
            } catch (error) {
                console.error("Error fetching available types: ", error);
            } finally {
                setLoading(false);
            }
        };

        fetchAvailableTypes();
    }, []);

    const handleClose = () => {
        setSnackBarEnable(false);
    }

    const handleSaveItem = async () => {
        setLoading(true);
        try {
            setSnackBarMessage('Aba criada com sucesso!');
            saveNewItem({
                name: newItemName,
                description: newItemDescription,
                type: newItemName.toLowerCase().replace(/\s+/g, '_')
            });
            setSnackBarEnable(true);
        } catch (error) {
            console.error("Error saving item: ", error);
        } finally {
            setLoading(false);
        }
    };

    const handleSavePortfolio = async () => {
        setLoading(true);
        try {
            await addPortfolio({
                description: portfolioDescription,
                image: portfolioImage,
                type: portfolioType
            });
            setSnackBarMessage('Portifólio adicionado com sucesso!');
            setSnackBarEnable(true);
        } catch (error) {
            console.error("Error adding portfolio: ", error);
        } finally {
            setLoading(false);
        }
    };

    const handleTabChange = (event, newValue) => {
        setTabIndex(newValue);
    };

    const handleImageUpload = async (event) => {
        const file = event.target.files[0];
        if (file) {
            const reader = new FileReader();
            reader.onloadend = () => {
                const base64String = reader.result.split(',')[1];
                setPortfolioImage(base64String);
                setIsButtonDisabled(true);
            };
            reader.readAsDataURL(file);
        }
    };

    const handleShowImage = () => {
        setShowImage(true);
    };
    const handleCloseDialog = () => {
        setShowImage(false);
    };

    return (
        <Container sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', padding: 2, maxWidth: '90%' }}>
            <Box sx={{ width: '100%', maxWidth: '1200px', marginTop: 1, marginBottom: 1 }}>
                <Typography variant="h4" gutterBottom sx={{ fontFamily: 'Times New Roman' }}>
                    Administração
                </Typography>
                <Tabs value={tabIndex} onChange={handleTabChange} centered>
                    <Tab label="Criar nova aba" />
                    <Tab label="Adicionar novo portifólio" />
                </Tabs>
                {tabIndex === 0 && (
                    <Box sx={{ marginTop: 4 }}>
                        <Typography variant="h6" sx={{ fontFamily: 'Times New Roman' }}>Nova aba</Typography>
                        <TextField
                            fullWidth
                            label="Nome da aba"
                            value={newItemName}
                            onChange={(e) => setNewItemName(e.target.value)}
                            margin="normal"
                            sx={{ fontFamily: 'Times New Roman' }}
                        />
                        <TextField
                            fullWidth
                            label="Descrição da aba"
                            value={newItemDescription}
                            onChange={(e) => setNewItemDescription(e.target.value)}
                            margin="normal"
                            sx={{ fontFamily: 'Times New Roman' }}
                        />
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={handleSaveItem}
                            sx={{ marginTop: 2 }}
                            disabled={!buttonAbaEnable}
                        >
                            Criar
                        </Button>
                    </Box>
                )}
                {tabIndex === 1 && (
                    <Box sx={{ marginTop: 4 }}>
                        <Typography variant="h6" sx={{ fontFamily: 'Times New Roman' }}>Novo portifólio</Typography>
                        <TextField
                            fullWidth
                            label="Descrição"
                            value={portfolioDescription}
                            onChange={(e) => setPortfolioDescription(e.target.value)}
                            margin="normal"
                            sx={{ fontFamily: 'Times New Roman' }}
                        />
                        <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                            <Button
                                variant="contained"
                                component="label"
                                sx={{ marginTop: 2 }}
                                color={isButtonDisabled ? 'info' : 'primary'}
                                disabled={portfolioImage}
                            >
                                {portfolioImage ? 'Imagem selecionada' : 'Selecione a imagem'}
                                <input
                                    type="file"
                                    hidden
                                    accept="image/*"
                                    onChange={handleImageUpload}
                                />
                            </Button>
                            <Button
                                variant="contained"
                                sx={{ marginTop: 2 }}
                                color="secondary"
                                onClick={handleShowImage}
                                disabled={!portfolioImage}
                            >
                                Exibir Imagem
                            </Button>
                        </Box>
                        <Dialog
                            open={showImage}
                            onClose={handleCloseDialog}
                            maxWidth="sm"
                            fullWidth
                            PaperProps={{
                                style: {
                                    width: '80%',
                                    maxWidth: '600px',
                                    margin: 'auto'
                                }
                            }}
                        >
                            <DialogTitle>Imagem escolhida</DialogTitle>
                            <DialogContent>
                                {portfolioImage && (
                                    <img
                                        src={`data:image/jpeg;base64,${portfolioImage}`}
                                        alt="Imagem do Portfólio"
                                        style={{ width: '100%', height: 'auto' }}
                                    />
                                )}
                            </DialogContent>
                            <DialogActions>
                                <Button onClick={handleCloseDialog} color="primary">
                                    Fechar
                                </Button>
                            </DialogActions>
                        </Dialog>
                        <TextField
                            fullWidth
                            select
                            label="Selecione"
                            value={portfolioType}
                            onChange={(e) => setPortfolioType(e.target.value)}
                            margin="normal"
                            sx={{ fontFamily: 'Times New Roman' }}
                        >
                            {availableTypes.map((type, index) => (
                                <MenuItem key={index} value={type}>
                                    {type}
                                </MenuItem>
                            ))}
                        </TextField>
                        <Button
                            variant="contained"
                            color={loading ? 'success' : 'primary'}
                            onClick={handleSavePortfolio}
                            sx={{ marginTop: 2 }}
                            disabled={!buttonPortifolioEnable}
                        >
                            Criar
                        </Button>
                    </Box>
                )}
                <Snackbar
                    open={snackBarEnable}
                    autoHideDuration={6000}
                    onClose={handleClose}
                    message={snackBarMessage}
                    anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                    sx={{
                        width: '300px',
                        color: '#fff',
                        mt: 4,
                    }}
                    ContentProps={{
                        sx: {
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                        },
                    }}
                />
            </Box>
        </Container>
    );
};

export default Register;
