import React from 'react';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Button from '@mui/material/Button';
import { Link } from 'react-router-dom';
import Box from '@mui/material/Box';
import logo from '../../assets/logo.png';

const Header = ({ activePage }) => {
  return (
    <AppBar position="static" sx={{ backgroundColor: '#d0d0d0', width: '100%' }}>
      <Toolbar sx={{ padding: '0 10px', height: 'auto', minHeight: '60px' }}>
        <Box sx={{ flexGrow: 1, display: 'flex', alignItems: 'center', overflow: 'hidden' }}>
          <Box sx={{ marginLeft: { xs: '0', sm: '10px' }, display: 'flex', alignItems: 'center' }}>
            <img src={logo} alt="Logo" style={{ height: 'auto', maxWidth: '150px' }} />
          </Box>
        </Box>
        <Box>
          <Button
            sx={{
              color: '#000000',
              fontWeight: activePage === 'about' ? 'bold' : 'normal',
              fontSize: activePage === 'about' ? '1rem' : undefined,
              fontFamily: '"Times New Roman", Times, serif',
              padding: '8px 16px',
            }}
            component={Link}
            to="/about"
          >
            Quem Somos
          </Button>
          <Button
            sx={{
              color: '#000000',
              fontWeight: activePage === 'portfolio' ? 'bold' : 'normal',
              fontSize: activePage === 'portfolio' ? '1rem' : undefined,
              fontFamily: '"Times New Roman", Times, serif',
              padding: '8px 16px',
            }}
            component={Link}
            to="/portfolio"
          >
            Portifólio
          </Button>
          <Button
            sx={{
              color: '#000000',
              fontWeight: activePage === 'contact' ? 'bold' : 'normal',
              fontSize: activePage === 'contact' ? '1rem' : undefined,
              fontFamily: '"Times New Roman", Times, serif',
              padding: '8px 16px',
            }}
            component={Link}
            to="/contact"
          >
            Contato
          </Button>
        </Box>
      </Toolbar>
    </AppBar>
  );
}

export default Header;
