import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
// import { getAnalytics } from "firebase/analytics";

const firebaseConfig = {
  apiKey: "AIzaSyBorYMEAA-U8G_H0xXh98NCj4AR97KJfTQ",
  authDomain: "jb-esquadrarias.firebaseapp.com",
  projectId: "jb-esquadrarias",
  storageBucket: "jb-esquadrarias.appspot.com",
  messagingSenderId: "338778792517",
  appId: "1:338778792517:web:6a6ee01a2e625e7a9bf92c",
  measurementId: "G-J110ZD7Q0L"
};

const app = initializeApp(firebaseConfig);
// const analytics = getAnalytics(app);

export const db = getFirestore(app);