import React, { useEffect, useState } from 'react';
import PortfolioHeader from '../../components/PortfolioHeader';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import CircularProgress from '@mui/material/CircularProgress';
import { fetchItems } from '../../dataprovider/items/itemsRepository';
import { fetchPortifolio } from '../../dataprovider/portifolio/portifolioRepository';
import { keyframes } from '@mui/system';
import { styled } from '@mui/system';
import Container from '@mui/material/Container';

const fadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

const blink = keyframes`
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
`;

const AnimatedContainer = styled(Container)`
  animation: ${fadeIn} 1.5s ease-in-out;
`;

const Portfolio = () => {
  const [items, setItems] = useState([]);
  const [selectedItem, setSelectedItem] = useState({
    name: '',
    description: '',
    type: ''
  });

  const [portifolio, setPortifolio] = useState([]);
  const [open, setOpen] = useState(false);
  const [currentImage, setCurrentImage] = useState('');
  const [dialogTitle, setDialogTitle] = useState('');
  const [descriptionDialog, setDescriptionDialog] = useState('');
  const [loading, setLoading] = useState(false);
  const [headerBlinking, setHeaderBlinking] = useState(true); // Estado para controlar o piscar

  useEffect(() => {
    const fetchData = async () => {
      try {
        const fetchedItems = await fetchItems();
        fetchedItems.sort((a, b) => a.name.localeCompare(b.name));
        setItems(fetchedItems);

        if (fetchedItems.length > 0) {
          handleItemClick(fetchedItems[0]);
        }
      } catch (error) {
        console.error("Error fetching items:", error);
      }
    };

    fetchData();

    const blinkTimeout = setTimeout(() => {
      setHeaderBlinking(false);
    }, 3000);

    return () => clearTimeout(blinkTimeout);
  }, []);

  const handleItemClick = async (item) => {
    setSelectedItem(item);
    setLoading(true);
    try {
      const portifolioFirebase = await fetchPortifolio(item.type);
      setPortifolio(portifolioFirebase);
    } catch (error) {
      console.error("Error fetching portfolio:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleImageClick = (image, description) => {
    setCurrentImage(image);
    setDialogTitle(selectedItem.name);
    setDescriptionDialog(description);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const bounceAnimation = keyframes`
    0%, 20%, 50%, 80%, 100% {
      transform: translateY(0);
    }
    40% {
      transform: translateY(-10px);
    }
    60% {
      transform: translateY(-5px);
    }
  `;

  return (
    <AnimatedContainer>
      <Box sx={{
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        padding: '0 20px',
        boxSizing: 'border-box'
      }}>
        <Box sx={{
          width: '100%',
          maxWidth: '1200px',
          marginTop: '100px',
          animation: headerBlinking ? `${blink} 1s ease-in-out infinite` : 'none',
        }}>
          <PortfolioHeader
            items={items}
            selectedItem={selectedItem}
            onItemClick={handleItemClick}
          />
        </Box>
      </Box>
      <Box sx={{ padding: '20px', textAlign: 'center' }}>
        {loading ? (
          <CircularProgress sx={{
            color: '#4caf50',
            border: '3px solid rgba(0, 0, 0, 0.1)',
            borderRadius: '50%',
            boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)'
          }} />
        ) : (
          <Typography
            variant="h4"
            component="h4"
            sx={{
              fontFamily: '"Times New Roman", Times, serif'
            }}
          >
            {loading ? '' : portifolio.length === 0 ? 'Nenhum item encontrado' : ''}
          </Typography>
        )}
      </Box>
      <Box sx={{
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'center',
        gap: '20px',
        padding: '20px',
        '@media (max-width: 600px)': {
          flexDirection: 'column',
          alignItems: 'center'
        }
      }}>
        {loading ? null : portifolio.length === 0 ? (
          <Typography variant="h6" component="div" sx={{ padding: '20px' }}>
          </Typography>
        ) : (
          portifolio.map((item, index) => (
            <Card key={index} sx={{
              maxWidth: 345,
              boxShadow: 3,
              '@media (max-width: 600px)': {
                maxWidth: '100%'
              }
            }}>
              {item.image && (
                <CardMedia
                  component="img"
                  height="300"
                  image={`data:image/jpeg;base64,${item.image}`}
                  alt={item.description || 'Portfolio Image'}
                  sx={{ cursor: 'pointer' }}
                  onClick={() => handleImageClick(item.image, item.description)}
                />
              )}
              <CardContent>
                <Typography
                  variant="body2"
                  color="text.secondary"
                  sx={{
                    fontFamily: '"Times New Roman", Times, serif'
                  }}>
                  {item.description || 'No Description'}
                </Typography>
              </CardContent>
            </Card>
          ))
        )}
      </Box>
      <Dialog
        open={open}
        onClose={handleClose}
        maxWidth="md"
        fullWidth
        PaperProps={{
          sx: { border: 'none' }
        }}
      >
        <DialogTitle>
          <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
            <Typography variant="h6" sx={{
              fontFamily: '"Times New Roman", Times, serif'
            }}>{dialogTitle}</Typography>
            <IconButton
              edge="end"
              color="inherit"
              onClick={handleClose}
              sx={{ padding: 0 }}
            >
              <CloseIcon />
            </IconButton>
          </Box>
        </DialogTitle>
        <DialogContent>
          <img
            src={`data:image/jpeg;base64,${currentImage}`}
            alt="Expanded"
            style={{ width: '100%', height: 'auto' }}
          />
          <Typography
            variant="body2"
            color="text.secondary"
            sx={{
              marginTop: '10px',
              paddingTop: '10px',
              textAlign: 'center',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              fontFamily: '"Times New Roman", Times, serif'
            }}
          >
            {descriptionDialog}
          </Typography>

          <Box sx={{ textAlign: 'center', marginTop: '20px' }}>
            <IconButton
              sx={{
                animation: `${bounceAnimation} 2s infinite`,
                fontSize: '40px',
                color: '#25D366'
              }}
              onClick={
                () => window.open(
                  `https://api.whatsapp.com/send?phone=+5516991839350&text=Ola, gostaria de saber mais sobre isso: ${descriptionDialog}`,
                  '_blank'
                )
              }
            >
              <WhatsAppIcon fontSize="inherit" />
            </IconButton>
          </Box>
        </DialogContent>
      </Dialog>
    </AnimatedContainer>
  );
}

export default Portfolio;
